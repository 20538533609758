import { up } from "styled-breakpoints"
import styled from "styled-components"

const Article = styled.article`
  width: 100%;
  margin: ${({ theme: { spacing } }) => spacing.lg} auto;

  @supports (display: grid) {
    display: grid;
    grid-template-columns: [block-start full-start content-start] 1fr [aside] 4fr 1fr [block-end full-end content-end];
    grid-template-rows: auto;
    grid-column-gap: 0.85rem;

    ${up("lg", "landscape")} {
      grid-template-columns: [full-start block-start content-start] 9fr [block-end content-end aside] 3fr [full-end];
    }
    ${up("xl")} {
      grid-template-columns: [full-start block-start content-start] 9fr [block-end content-end aside] 3fr [full-end];
    }

    p {
      grid-column: content;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      grid-column: block;
    }

    aside {
      grid-column: aside;
    }

    blockquote,
    figure {
      display: grid;
      margin: 0;
      grid-template-columns: [block-start full-start content-start] 1fr [aside] 4fr 1fr [block-end full-end content-end];
      grid-template-rows: auto;

      ${up("lg", "landscape")} {
        grid-template-columns: [full-start block-start content-start] 9fr [block-end content-end aside] 3fr [full-end];
      }
      ${up("xl")} {
        grid-template-columns: [full-start block-start content-start] 9fr [block-end content-end aside] 3fr [full-end];
      }

      grid-column-gap: 0.85rem;
      grid-column: full;
    }
    figure img {
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      grid-column: full;
    }
    figure figcaption {
      grid-column: block;
    }

    blockquote p {
      font-size: 110%;
      font-style: italic;
      color: darkgray;

      grid-column: block;
    }
    blockquote cite {
      grid-column: content;
      font-style: normal;
      font-size: 85%;

      &::before {
        content: "— ";
      }
    }
  }
`

export default Article
