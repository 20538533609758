import React, { Component } from "react"
import { graphql, Link } from "gatsby"

import Article from "../components/Article"
import Brand from "../components/Brand"
import Container from "../components/Container"
import { BareLayout as Layout } from "../components/Layout"
import SEO from "../components/SEO"

class PageTemplate extends Component {
  render() {
    const currentPage = this.props.data.wordpressPage

    return (
      <Layout>
        <SEO title="The Fumble Ducks" />
        <Brand full />

        <Container as="section">
          <header>
            <em>
              <Link to={"/"}>Back to home page</Link>
            </em>
            <h2 dangerouslySetInnerHTML={{ __html: currentPage.title }} />
          </header>
          <Article dangerouslySetInnerHTML={{ __html: currentPage.content }} />
        </Container>
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
    }
    allWordpressCategory {
      edges {
        node {
          id
          name
          description
          slug
        }
      }
    }
    site {
      id
      siteMetadata {
        title
        subtitle
      }
    }
  }
`
